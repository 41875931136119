<template>
    <v-app-bar dense text class="fixedToolbar" style="z-index: 2;width: 100%;">
        <v-btn v-bind:class="{'headline grey-text': !drawer, 'padLeft headline grey-text': drawer }"
               text
               fab
               small
               @click="previousDate()">
            <v-icon dark>fas fa-angle-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px">
            <template v-slot:activator="{ on }">
                <!--<v-text-field v-model="date"
                              readonly
                              v-on="on"></v-text-field>-->
                <v-chip class="ma-2" 
                        v-on="on">
                    {{date}}
                </v-chip>
            </template>
            <v-date-picker ref="picker"
                           v-model="date"
                           :type="dateType"
                           min="1950-01-01"
                           @change="save"></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn text
               fab
               small
               @click="nextDate()">
            <v-icon dark>fas fa-angle-right</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
    export default {
        props: ['type', 'drawer','startDate'],
        data() {
            return {
                date: null,
                menu: false,
                dateType: null
            }
        },
        methods: {
            save(date) {
                this.$refs.menu.save(date)
                this.returnDateChanged()
            },
            returnDateChanged: function () {
                this.$emit('updateDate', this.date)
            },
            nextDate: function () {
                this.$emit('next')
            },
            previousDate: function () {
                this.$emit('previous')
            },
        },
        watch: {
            "startDate": function () {
                this.date = this.startDate
            }
        },
        beforeMount() {
            this.dateType = this.type
            this.date = this.startDate
        }
    }
</script>